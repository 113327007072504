.tarifContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tarifItems {
  width: calc(100% - 80px);
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.tarifItem {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .tarifItems {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .tarifItem {
    margin-right: 0;
  }

  /* .tarifItem article {
    width: 100%;
    position: relative;
  } */

  div.tarifItem div.card {
    width: 100%;
    position: relative;
  }
}

@media screen and (max-width: 400px) {
  .tarifItem {
    width: 100%;
  }
}