h1 {
    text-align: center;
    color: orangered;
}

.page_wrapper {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
}

.background_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/new_images/back5.png") no-repeat center center;
    background-size: cover;
    z-index: 0;
}

.content_container {
    position: relative;
    z-index: 1;
}

.all_page {
    color: white;
    margin: 40px;
}
.link{
    color: orangered;
}
.para{
    text-align: center;
}