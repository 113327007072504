.premiumContainer {
  width: 100vw;
  margin: 0 auto;
  padding: 20px;
  /*display: flex;*/
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.premiumImage {
  width: 476px;
  height: auto;
}

@media screen and (max-width: 1300px) {
  .premiumContainer {
    width: 100vw;
  }
}

@media screen and (max-width: 1100px) {
  .premiumImage {
    width: 380px;
  }

  .premiumContent {
    width: 100%;
    margin-inline: 0;
  }
}

@media screen and (max-width: 1000px) {
  .premiumContainer {
    width: 100vw;
    flex-direction: column;
    height: auto;
  }

  .premiumImage {
    width: 320px;
    margin: 0;
  }

  .premiumContent h2 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media screen and (max-width: 576px) {
  .premiumContainer {
    width: 100%;
  }

  .premiumContent h2 {
    width: 100%;
    text-align: left;
  }

  .premiumContent span {
    width: 100%;
    text-align: left;
  }

  .premiumContent > div {
    width: 100%;
    z-index: 1;
  }
}