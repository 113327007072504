@media screen and (max-width: 1000px) {
  .whySection {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .whyHeader {
    width: 100%;
    margin: 0;
  }

  .whyHead > span {
    width: 100%;
    /*margin-top: 20px;*/
  }

  .whyHeader div.slide {
    width: 200px;
  }

  .whyHeader img {
    width: 100px;
    margin-right: 15px;
  }

  .whyItem,
  .whyItem2 {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .whyTextSection > h3 {
    /*margin-bottom: 20px;*/
  }

  img.whyImage {
    width: 300px;
  }
}

@media screen and (max-width: 900px) {
  .whyItem {
    flex-direction: column-reverse;
  }

  .whyItem2 {
    flex-direction: column;
  }

  .whyItem:not(:first-child),
  .whyItem2 {
    /*margin-top: 30px;*/
  }

  img.whyImage {
    width: 250px;
    margin: 0;
  }

  .whyTextSection > h3 {
    font-size: 40px;
    line-height: 60px;
  }
}

@media screen and (max-width: 576px) {
  .whyTextSection > h3 {
    line-height: 50px;
  }
}