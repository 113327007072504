.position{
    width: 1em;
}

.slick-slide {
    transition: transform 0.5s ease-in-out; /* Adjust duration and easing function */
}

/* YourContinuousCarousel.css */
.continuous-carousel {
    display: flex;
    overflow: hidden;
    animation: scroll 20s linear infinite; /* Adjust the duration as needed */
}

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%); /* Adjust based on the number of items */
    }
}

.card {
    flex: 0 0 auto; /* Adjust based on your card size */
}
