.footerContainer {
  width: 100%;
}
.footerContainer1 {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: auto;
  align-items: flex-start;
}

.footerBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1em;
}

@media screen and (max-width: 1250px) {
  .footerBlock {
    flex-direction: column;
    flex-wrap:wrap;
  }
  .footerContainer1 {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .footerContainer1 {
    flex-wrap: wrap;
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 4em;
  }

  .footerBlock {
    width: 160px;
    align-items: flex-start;
    flex-direction: revert;
    flex-wrap: wrap;
    margin-right: 0.5em;
  }

  .footerBlock:first-child{
    width: 100%;
  }

  .footerBlock:last-child{
    order: -1;
  }

  .footerContent {
    width: fit-content;
  }
}

@media screen and (max-width: 390px) {
  .footerContainer1 {
    flex-wrap: wrap;
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 1em;
  }
}