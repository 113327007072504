* {
  box-sizing: border-box;
}

:root {
  --radius-1: 70%;
  --radius-2: 30%;
  --radius-3: 30%;
  --radius-4: 70%;
  --radius-5: 60%;
  --radius-6: 40%;
  --radius-7: 60%;
  --radius-8: 40%;
}

a {
  text-decoration: none;
}

.body {
  background: black;
  width: 100vw;
}

.blob {
  width: 520px;
  height: 520px;
  border-radius: var(--radius-1) var(--radius-2) var(--radius-3) var(--radius-4) / var(--radius-5) var(--radius-6) var(--radius-7) var(--radius-8);
  background-color: #21D4FD;
  background-image: linear-gradient(-10deg, #FF1751 0%, #FF9F03 75%, #22F642 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  position: absolute;
  top: -80px;
  right: -116px;
  /*z-index:1;*/
}

.b2 {
  width: 520px;
  height: 520px;
  border-radius: var(--radius-1) var(--radius-2) var(--radius-3) var(--radius-4) / var(--radius-5) var(--radius-6) var(--radius-7) var(--radius-8);
  background-color: #21D4FD;
  background-image: linear-gradient(-10deg, #FF1751 0%, #FF9F03 75%, #22F642 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  position: absolute;
  top: 302px;
  left: -456px;
  transform: rotate(45deg);
  /*z-index:1;*/
}

.viewport {
  margin: 0 0 0 0;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  position: fixed;
}

.device-grid-container {
  display: grid;
  align-content: start;
  position: relative;
  padding-top: 7em;
  justify-content: center;
  /*  width: 100vw; */
  /*height: 100vh;*/
  background-color: black;
  overflow: visible;
  /*  overflow: hidden; */
}

.device-grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*margin-top: 25em;*/
  gap: 32px;
  /*display: grid;*/
  /*grid-template-columns: repeat(3, 33.333%);*/
  /*grid-template-rows: repeat(3, 33.333%);*/
  /*max-width: 1200px;*/
  /*z-index: 4;*/
  /*margin-top: -320px;*/

}

.infinite-carousel {
  display: flex;
  gap: 6em;
  overflow: hidden;
  animation: slide 10s linear infinite;
  /* Adjust the duration as needed */
}

.carousel-item {
  /* Adjust the background color as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% * var(--item-count, 5)));
    /* Default count is 5, adjust as needed */
  }
}


.device-subgrid {
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  display: flex;
  width: 638px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  /*display: grid;*/
  /*grid-template-columns: repeat(3, 33.333%);*/
  /*grid-template-rows: repeat(3, 33.333%);*/
  /*max-width: 1200px;*/
  /*z-index: 1000;*/
  margin-top: -280px;

}

.device1 {
  width: 600px;
  margin: 296px 0 0 -16px;
}

.device2 {
  width: 200px;
  height: 200px;
  margin: 20px 0 0 0;
}

.playful {
  position: absolute;
  top: 110px;
  right: 100px;
  width: 490px;
  height: 490px;
  border-radius: 50%;
  border: 5px solid #c4c4c4;
  border-style: dotted;
  /*z-index: 3;*/
}

.playit {
  position: absolute;
  top: 110px;
  right: 88px;
  width: 440px;
  height: 440px;
  border-radius: 50%;
  /*z-index: 3;*/
  overflow: visible;
}

.device3 {
  width: 1000px;
  height: 718px;
  margin: 200px 0 0 0;
}

.phone {
  margin-top: 110%;
  z-index: 3;
  max-width: 150%;
  margin-left: -84%;
}

.mac {
  margin-top: 21%;
  z-index: 3;
  max-width: 60%;
  margin-left: 33%;
}

.hero-container {
  padding: 0 88px 88px 88px;
  height: 100vmin;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
}

.intro1 {
  position: absolute;
  height: 918px;
  width: 1200px;
  margin: 200px -200px 0 0;
}

.intro2 {
  height: 978px;
  width: 600px;
  position: absolute;
  margin: -400px 0 0 0;
}
.navbarMobileMenu_mobileMenuContainer__2pdLH {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  width: 400px;
  height: 100vh;
  background-color: rgba(22, 22, 22, 1);
  color: white;
  opacity: .95;
  box-shadow: 0 1px 3px #ccc;
  padding: 20px;
  z-index: 20000;
  transform: translateX(-100%);
  transition: transform .3s;
}

.nav-bar {
  position: absolute;
  background: url("./assets/new_images/light_from_scy.svg");
  background-position: center;
  height: 100%;
  background-size: cover;
  color: white;
  width: 100%;
  position: fixed;
  z-index: 2000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 48px;
  pointer-events: none;
}

.nav-bar * {
  pointer-events: auto;
}

.nav-bar .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  z-index: 5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  display: none;
  opacity: 0;
}

/*.nav-elts{
  list-style: none;
  
  padding-top: 16px;
}
.nav-elts li{
  font-size: 16px;
  font-weight: 648;
  padding-left: 40px;
  display: inline-block;
  cursor: pointer;
} */


.lsdro {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-right: 5px;
  color: white;
  outline: none;
  padding: 0px;
}

.lsdrp {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0px;
  font-size: 26px;
  font-weight: 600;
  margin-top: 16px;
  margin-left: 20px;
}

/* .nav-bar{*/
/*  width: 100%;*/
/*  position: fixed;*/
/*  z-index: 5;*/
/*  background-color: #000000;*/
/*  color: #FFFFFF;*/
/*   font-size: 16px;*/
/*   font-style: normal;*/
/*   font-weight: 400;*/
/*!*box-shadow: -1px 6px 6px 0px rgba(0,0,0,0.1);*!*/
/*!*-webkit-box-shadow: -1px 6px 6px 0px rgba(0,0,0,0.1);*!*/
/*!*-moz-box-shadow: -1px 6px 6px 0px rgba(0,0,0,0.1);*!*/
/*} */
.nav-elts {
  list-style-type: none;
  float: left;
  right: 48px;
  position: fixed;
  color: white;
  z-index: 8 !important;
}

.nav-elts li {
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable", serif;*/
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 5em;
  z-index: 8 !important;
  display: inline-block;
  cursor: pointer;
}

.links4 {
  height: 35px;
  cursor: pointer;
}

.links2 {
  width: 20px;
  height: 35px;
  cursor: pointer;
}
.links1 {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.links {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.links5 {
  width: 90px;
  height: 90px;
  cursor: pointer;
}

.links3 {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.nav-logo {
  float: left;
  display: block;
  margin-left: 40px;
  z-index: 8;
  margin-top: 25px;
}

.nav-bar img {
  width: 135.36px;
  flex-shrink: 0;
}

.auth-cta {
  list-style-type: none;
  display: flex;
  margin-right: -8px;
}

.hero-title {
  width: 1010px;
  letter-spacing: -0.41px;
  /*z-index: 2;*/
  color: var(--White, #FFF);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 60px */
}

.btn-disc {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  border: 1px solid var(--White, #FFF);
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  font-size: 24px;
  font-style: normal;
  background-color: transparent;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.btn-disc2 {
  cursor: pointer;
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  margin-top: 1em;
  border: none;
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  font-size: 24px;
  font-style: normal;
  background-color: #FF5620;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  position: relative;
}

.btn-disc3 {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  margin-top: 1em;
  margin-bottom: 1em;
  border: 1px solid var(--White, #FFF);
  color: var(--White, #FFF);
  font-size: 24px;
  font-style: normal;
  background-color: transparent;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  position: relative;
}
.pointImg{
  width: 100%;
  padding: 1em;
  padding-left: 0;
  padding-right: 0;
}
.point{
  width: 100%;
  height: auto;
}

.background-container {
  margin-top: 1em;
  background-image: url('assets/new_images/carou.png'), url('assets/new_images/transparent.png');
  background-blend-mode: multiply; /* Exemple de mode de fusion, ajustez selon le besoin */
  background-size: cover; /* Assure que les images couvrent entiÃ¨rement l'Ã©lÃ©ment */
  background-position: center; /* Centre les images dans l'Ã©lÃ©ment */
  background-repeat: no-repeat; /* EmpÃªche la rÃ©pÃ©tition des images */
  height: 550px; /* DÃ©finissez la hauteur nÃ©cessaire pour votre design */
  width: 100%; /* La largeur peut Ãªtre ajustÃ©e selon le design */
}

.imgbtn {
  width: 24px;
  height: 24px;
}

.row {
  display: flex;
  gap: 30px;
  z-index: 1;
}

.btn-freedown {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0px;
  border-radius: 54px;
  background: #FF5620;
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.botsect1 {
  width: 100%;
  padding-top: 2em;
  position: relative;
  /*justify-content: center;*/
  /* overflow: hidden; */
  /* z-index: 3000 !important; */
}

.phone_in_hand {
  position: relative;
  /*justify-self: center;*/
  /*align-self: center;*/
  left: 40%;
  /*flex-shrink: 0;*/
}

.discover {
  position: absolute;
  top: 50%;
  left: 30%;
}

.disk {
  position: absolute;
  top: 50%;
  left: 0;
}

.playlist {
  position: absolute;
  right: 10%;
  top: 40%;
}

.song1 {
  position: absolute;
  top: 72%;
  right: 5%;
}

.song {
  position: absolute;
  top: 70%;
  right: 0;
}

.song2 {
  position: absolute;
  top: 25%;
  left: 15%;
}

.listening1 {
  position: absolute;
  right: 5%;
  top: 2%;
}

.listening2 {
  position: absolute;
  top: 2%;
  left: 15%;
}

.event {
  position: absolute;
  top: 2%;
  left: 35%;
}
.popup {
  position: fixed;
  top: 5px;
  right: 5px;
  background-color: orangered;
  border: 1px solid #636363;
  color: #000;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 5px;
  z-index: 3000;
  width: 300px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

/* .popup-header h3 {
  font-size: 18px;
  font-weight: bold;
  color: orangered;
  margin: 0;
} */

.popup-header button {
  position: absolute;
  right: 5px;
  top: 3px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.popup-header button:hover {
  color: #000000;
}

.popup-content {
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width: 1300px) {}

.hero-subtitle {
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  width: 638px;
  letter-spacing: 1.5;
  margin-top: -40px;
  z-index: 2;
}


.hero-subtitle2 {
  width: 50%;
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  margin-top: -2em;
  /*font-family: "Satoshi Variable";*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}

.hero-subtitle3 {
  color: #CCC;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  margin-top: -2em;
  font-weight: 500;
  width: 80%;
  line-height: 150%;
}

.hero-subtitle6 {
  color: #CCC;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  margin-top: -2em;
  font-weight: 500;
  width: 40%;
  line-height: 150%;
  /* 24px */
}

.cta-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: -8px;
}

.cta-container a {
  text-decoration: none;
}

.hero-cta {
  /* max-width: 220px; */
  width: auto;
  padding: 12px;
  font-size: 13pt;
  font-weight: 400;
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
  z-index: 3;
  margin-right: 12px;
}

.hero-ctah {
  cursor: pointer;
  max-width: 220px;
  padding: 14px;
  font-size: 13pt;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(to right, #FF2447, #FF9D04);
  border-radius: 8px;

  z-index: -4;
}

.logo-container {
  width: 600px;
  height: 600px;
  margin-left: 24px;
  object-fit: cover;
  z-index: 3;
}

.tcon {
  display: block;

}

.why-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 60px;
}

.fulll {
  background: black;
  width: 100vw;
}

.why-head1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2em;
  margin: auto;
}

.carous {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  width: 100%;
  gap: 2em;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* Pour un dÃ©filement fluide sur iOS */
  scrollbar-width: none; /* Pour Firefox */
  -ms-overflow-style: none; /* Pour Internet Explorer et Edge */
  /*background: url("./assets/new_images/back5.png");*/
  /*background-repeat: no-repeat;*/
  /*background-position: center;*/
  /*background-size: cover;*/
}

.carous::-webkit-scrollbar {
  height: 10px; /* Chrome, Safari, Opera */
}

.premiumpart {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
  width: 100%;
  overflow-x: hidden; 
}

.left {
  grid-column: 1 / 2;
}

.right {
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.app-preview {
  width: 100%;
}

.carous2 {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 1.5em;
  margin-bottom: -1.5em;
  gap: 0.1em;
}

.carous3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px;
  margin-bottom: -1.5em;
  gap: 5em;
}

.carousimgprime {
  height: auto;
  width: 280px;
}

.carous4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5em;
}

.icon {
  position: absolute;
  right: -130px;
  bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000 !important;
}

.appStore {
  width: 110px;
  padding-right: 3px;
}

.playStore {
  width: 32px;
}

.carous-elt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 44%;
  margin-right: 1.83em;
  padding-bottom: 1em;
}

.carous-elt1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 1em;
}

.carousspan1 {
  width: 50px;
  text-align: center;
}

.subimg {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2em;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 5.045px 21.019px 0px rgba(30, 215, 96, 0.08);
  margin-right: auto;
  /* margin-top: 10px;
  margin-bottom: 10px; */
}

.subimgp {
  width: 30px;
  height: 30px;
}

.subtil {
  color: var(--White, #FFF);
  text-align: left;
  font-size: 18.497px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  align-self: stretch;
}

.subsubtil {
  width: 240px;
  color: rgba(255, 255, 255, 0.80);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  align-self: stretch;
}

.back5 {
  position: absolute;
  bottom: 0;
  left: 19em;
  width: 70%;
}

.why-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*margin: 0 88px 0 88px;*/
  padding: 75px 15px 75px 15px;
  background-color: black;
}

.why-title {
  color: var(--White, #FFF);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 60px */
  width: 743px;
  /*font-size: 24px;*/
  /*font-weight: 600;*/
  /*line-height: 33.6px;*/
  /*text-align: center;*/
  max-width: 800px;
  width: 90%;
  margin: 0 0 16px 0;
}

.why-title1 {
  color: var(--White, #FFF);
  text-align: left;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  width: 782px;
}

.why-title2 {
  color: var(--White, #FF5620);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.why-title3 {
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.why-subtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  padding-bottom: 24px;
}

.why-con {
  display: flex;
  align-items: center;
  justify-content: center;
}

.texpart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
}

.visualpart {
  width: 356px;
  height: auto;
  margin-top: 92px;
  margin-left: 20px;
}

.servi1 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 21.85px;
  color: #d1541f;
  margin-bottom: -36px;
  text-align: left;
}

.carou {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-left: 20em;
  gap: 5em
}

.placeholder {
  width: 100%;
  height: 80%;
  position: relative;
}

.largecircle {
  position: absolute;
  border: 1px black solid;
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: 0;
  margin-top: -5em;
  mask-image: linear-gradient(to top, transparent, black);
  transform: translateX(-15%);
}

.backg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(to top, transparent, black);
  background: #FC263D;
}

.bigcircle {
  position: absolute;
  border: 1px black solid;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  top: 0;
  margin-top: -4em;
  mask-image: linear-gradient(to top, transparent, black);
  transform: translateX(-5%);
}

.mediumcircle {
  position: absolute;
  border: 1px black solid;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  top: 0;
  mask-image: linear-gradient(to top, transparent, black);
  transform: translateX(10%);
  margin-top: -1em;
}

.smallcircle {
  position: absolute;
  border: 1px black solid;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  top: 0;
  transform: translateX(40%);
  mask-image: linear-gradient(to top, transparent, black);
  margin-top: 1.5em;
}

.carouimg {
  width: 100%;
  height: auto;
  /*background: red;*/
  display: block;
  object-fit: cover;
  mask-image: linear-gradient(to top, transparent, black);
}


.carouimg2 {
  width: 100%;
  height: 100%;
  background: #25370E;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
  display: block;
  object-fit: fill;
  mask-image: linear-gradient(to top, transparent, black);
}

.carouimg22 {
  width: 100%;
  height: 100%;
  background: #3B3DD9;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
  display: block;
  object-fit: fill;
  mask-image: linear-gradient(to top, transparent, black);
}


.carouimg3 {
  width: 800px;
  overflow: hidden;
  background: red;
  display: block;
  object-fit: fill;
  mask-image: linear-gradient(to top, transparent, black);
}

.carouspan {
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 30px */
  align-self: baseline;
}

.car-elt {
  border-radius: 32px;
  background: linear-gradient(180deg, rgba(20, 20, 20, 0.00) 42.86%, #141414 78.4%);
  width: 360px;
  height: 568px;
  flex-shrink: 0;
  padding-bottom: 2em;
  overflow: hidden;
  text-align: center;
}

.car-div {
  width: 60px;
  background: transparent;
  height: 30px;
}

.carouspan1 {
  color: var(--White, #FFF);
  /*font-family: "Satoshi Variable";*/
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 30px */
  align-self: baseline;
}

.servi2 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 21.85px;
  color: #3aa374;
  margin-bottom: -36px;
}

.servi3 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 21.85px;
  color: #f7ca06;
  margin-bottom: -36px;
}

.servcta1 {
  max-width: 280px;
  background-color: #d1541f;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 16px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.servcta2 {
  max-width: 280px;
  background-color: #3aa374;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 16px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.servcta3 {
  max-width: 280px;
  background-color: #f7ca06;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 16px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
}

.servih2 {
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -0.56px;
  line-height: 64.4px;
  color: #1f2024;
}

.servdes {
  max-width: 592px;
  font-size: 20px;
  line-height: 28px;
  color: #626366;
}

.servcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -120px;
}

.servbo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.servbo span {
  max-width: 400px;
  text-align: center;
  margin: 28px;
}

.servcon span {
  width: 88px;
  height: 36px;
  border-radius: 8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff9f03;
  color: #fff;
}

.isl2 {
  max-width: 206px;
  max-height: 627px;
}

.why-ec {
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding-bottom: 48px;
}

.why-ec li {
  width: 294px;
  height: 294px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2px 160px 2px;
  margin-right: 28px;
  cursor: pointer;
}

.why-ec li img {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  margin: 8px 0 8px 0;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.why-ec li:hover img {
  transform: scale(1.06);
}

.why-ec span {
  font-size: 21px;
  line-height: 16.46px;
  font-weight: 600;
  letter-spacing: -1.5;
  margin: 20px 0 2px 0;
}

.why-ec p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5;
  text-align: center;
  width: 80%;
}

.freedown {
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 0.609px solid rgba(255, 255, 255, 0.16);
  background: #FF5620;
  color: white;
  z-index: 1000;
  cursor: pointer;
}

.premium-cta {
  position: relative;
  width: 100vw;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  padding: 60px 28px;
  color: #fff;
  background: black;
  text-align: center;
}

.premium-cta h2 {
  width: 496px;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 47.96px;
  margin-top: 12px;
}

.mock {
  margin-left: -100px;
  margin-right: 12px;
  margin-top: 10px;
}

.descri {
  width: 440px;
  text-align: left;
  font-size: 18px;
  line-height: 24.92px;
  font-weight: 400;
  margin: -8px 16px 56px 16px;
}

.rdiv {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 28px;
  margin-right: 40px;
}

.pcta {
  display: flex;
  flex-direction: row;
}

.events-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 88px 15px 75px 15px;
  background: black;
}

.events-cta2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 15px 75px 15px;
}

.partner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 88px 15px 75px 15px;
}

.events-cta h3 {
  text-align: center;
  /* font-size: 35px; */
  line-height: 45px;
  font-weight: 700;
}

.events-cta p {
  width: 50%;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
}

.isl {
  max-width: 346px;
  max-height: 667px;
  padding-left: 156px;
}

.txts {
  display: block;
}

.slh {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.roic {
  display: flex;
  width: 80px;
  height: 80px;
  margin-right: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background-color: #120301;
}

.slh h3 {
  max-width: 420px;
}

.prh {
  text-align: center;
}

.prh h2 {
  font-size: 36px;
  font-weight: 700;
}

.prh span {
  max-width: 140px;
}

.prc {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 384px;
  height: 440px;
  border-radius: 8px;
  border-image: linear-gradient(45deg, #FF2447, #FF9D04);
  margin-right: 20px;
  box-shadow: 0px 5px 25px #00000033;
}

.prctit {
  font-size: 25px;
  font-weight: normal;
  margin: 8px 0 6px 0;
  color: #3A170D;
}

.prche {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.prcsub {
  font-size: 20px;
  font-weight: 600;
  margin-top: 6px;
}

.prc ul {
  list-style-type: none;
  margin-top: 2px;
  margin-bottom: 32px;
}

.prc ul li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.pric {
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: linear-gradient(45deg, #FF2447, #FF9D04);
  margin-right: 24px;
}

.prcta {
  width: 120px;
  padding: 12px;
  border-radius: 6px;
  background: linear-gradient(45deg, #FF2447, #FF9D04);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.prcta2 {
  text-align: center;
  width: 120px;
  padding: 8px;
  border-radius: 6px;
  background: linear-gradient(45deg, #FF2447, #FF9D04);
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

.prb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px;
}

.psl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.psli {
  max-width: 48px;
  max-height: 56px;
  margin-right: 8px;
}


.partner-container h2 {
  width: 45%;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
}

.slide-container {
  position: relative;
  overflow: hidden;
  padding: 48px 0 20px 0;
}

.prnt-lg {
  filter: grayscale(100%);
  transition: .3s ease-out;
  margin-right: 24px;
  cursor: pointer;
  max-width: 250px;
  max-height: 100px;
}

.prnt-lga {
  filter: grayscale(100%);
  transition: .3s ease-out;
  margin-right: 24px;
  cursor: pointer;
  max-width: 136px;
  max-height: 146px;
}


.prnt-lg:hover {
  filter: grayscale(0%);
}


.footer {
  position: relative;
  width: 100%;
  background: black;
  color: #fff;
  padding-bottom: 1em;
}

.footer1 {
  position: relative;
  bottom: 0;
  width: 90%;
  margin: auto;
  /*padding: 28px 250px 70px 150px;*/
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  gap: 0.1em;
  color: #fff;
}

.copywright {
  width: 100%;
  margin: auto;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.langg {
  width: 2em;
  height: 2em;
  flex-shrink: 0;
  display: flex;
  /*background: url("./assets/new_images/english.svg");*/
  /*background-repeat: no-repeat;*/
  /*background-position: center;*/
  /*background-size: cover;*/
  border-radius: 1000px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.langg img {
  width: 60px;
  height: 60px;
}

.legals {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgba(255, 255, 255, 0.80);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  align-items: center;
  line-height: 150%;
  /* 24px */
}

.legals a {
  text-decoration: underline !important;
}

.languages {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.5em;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.12);
}

.languages span {
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
}

.ftr-logo {
  font-size: 20px;
  font-weight: 600;
  margin: 16px;
  padding-right: 70px;
}

.ftr-logo-img {
  width: 148px;
}

.ftr-content {
  width: auto;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.ftr-content2 {
  width: auto;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

}

.cont {
  color: rgba(255, 255, 255, 0.80);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  /* 21px */
  margin-bottom: 1em;
}

.ftr-content2 h3 {
  width: 100%;
  /*margin-left: 22px;*/
  color: var(--White, #FFF);
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ftr-content2 ul {
  margin: 0px;
  padding: 0px;
}

.ftr-content2 ul li {
  color: rgba(255, 255, 255, 0.80);
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  /* 24px */
  /*margin: 6px;*/
  width: 200px;
  cursor: pointer;
  list-style-type: none;
  /*font-size: 17px;*/
  margin: 0 8px 8px 0;
  /*font-weight: 500;*/
}


.ftr-content h3 {
  width: 100%;
  /*margin-left: 22px;*/
  color: var(--White, #FFF);
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ftr-content ul {
  margin: 0px;
  padding: 0px;
}

.ftr-content ul li {
  color: rgba(255, 255, 255, 0.80);
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  /* 24px */
  /*margin: 6px;*/
  width: 135px;
  cursor: pointer;
  list-style-type: none;
  /*font-size: 17px;*/
  margin: 0 8px 8px 0;
  /*font-weight: 500;*/
}

.follow {
  display: flex;
  gap: 1em;
  margin-left: -0.5em;

}

.follow a {
  text-decoration: none;
  color: white;
}

.footer1 a {
  text-decoration: none;
  color: #fff;
}

.footer a {
  text-decoration: none;
  color: #fff;
}

.downbtn {
  display: flex;
  gap: 1em;
}

.ftr-content3 {
  width: 120px;
  position: sticky;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-bottom: 5em;
}

.btn-icon {
  filter: invert(100%);
  margin-right: 8px;
}

.lastt {
  display: flex;
}

.color-text {
  background-image: -webkit-linear-gradient(-92deg, #d11c4c, #feab3a);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hue 8s infinite cubic-bezier(.23, 1, .32, 1);
}

.serv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px;
}

.servimg {
  width: 240px;
  height: 240px;
  border-radius: 120px;
  background-color: #f5db20;
}

.servtitle {
  font-size: 24px;
  font-weight: 700;
  margin: 16px;
}

.servtext {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 260px;
  text-align: center;
}

.fpc {
  width: 1191px;
  height: 880px;
  padding: 0 64px 80px 64px;
  background-color: #120301;
  border-radius: 8px;
}

.fph {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fph h3 {
  /* font-size: 84px; */
  font-weight: 700;
  line-height: 77, 56px;
  letter-spacing: -0.84px;
  color: #fff;
}

.fph span {
  width: 190px;
  height: 64px;
  padding: 14px;
  cursor: pointer;
  background-color: #21D4FD;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border-radius: 6px;
}

.fph a {
  text-decoration: none;
}

.fpb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -24px;
}

.scd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.sc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sci {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px;
  font-weight: 700;
  color: #fff;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #4d4d51;
  margin-right: 24px;
}

.scdc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.scdc h5 {
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  margin-bottom: -2px;
}

.scdc p {
  text-align: start;
  width: 420px;
  font-size: 20px;
  line-height: 28px;
  color: #e5e7eb;
}

.fpgif {
  width: 645px;
  height: 470px;
}

.fw {
  margin-bottom: -16px;
}

.plbc {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.plbcv {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.plb {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  border: 0px solid #eee;
}

.plbv {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  border: 0px solid #eee;
  font-size: 17px;
  font-weight: 500;
}

.linker {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  z-index: 1000;
  padding: 0px;
  cursor: pointer;
}

.fond {
  position: absolute;
  top: 0;
  /*z-index: 3;*/
  width: 100vw !important;
  height: 100%;
  /* background-color: red; */
  background: url("./assets/new_images/noise.svg");
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.fond2 {
  position: absolute;
  top: 0;
  width: 100vw !important;
  /*z-index: 3;*/
  flex-shrink: 0;
}

.fond3 {
  background: url("./assets/new_images/blurright.svg");
  position: absolute;
  background-size: contain;
  width: 100vw !important;
  height: 1090px;
  top: 0;
  left: 0;
  background-position: left;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.fond5 {
  position: absolute;
  background: url("./assets/new_images/blurleft.svg");
  width: 100vw !important;
  height: 1090px;
  bottom: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.fond4 {
  position: absolute;
  background: url("./assets/new_images/noise.svg");
  top: 0;
  left: 0;
  width: 100vw !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 95%;
  flex-shrink: 0;
}

.fond6 {
  position: absolute;
  background: url("./assets/new_images/last.svg");
  width: 100% !important;
  height: 150%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  bottom: 0;
  left: 0;
  /*display: block;*/
}


.pslb {
  position: absolute;
  border-radius: 50%;
}

.pl1 {
  top: 20%;
  left: 25%;
  width: 68px;
  height: 68px;
}

.pl2 {
  top: 60%;
  left: 20%;
  width: 68px;
  height: 68px;
}

.pl3 {
  top: 60%;
  left: 60%;
  width: 68px;
  height: 68px;
}

.pl4 {
  top: 20%;
  left: 60%;
  width: 68px;
  height: 68px;
}

.pl5 {
  top: 40%;
  left: 4%;
  width: 68px;
  height: 68px;
}

.pl6 {
  top: 80%;
  left: 40%;
  width: 68px;
  height: 68px;
}

.pl7 {
  top: 40%;
  left: 78%;
  width: 68px;
  height: 68px;
}

.pl8 {
  left: 86%;
  width: 68px;
  height: 68px;
}

.pl9 {
  padding-top: 280px;
  left: 68%;
  width: 68px;
  height: 68px;
}

.pl10 {
  padding-bottom: 100px;
  left: 85%;
  width: 68px;
  height: 68px;
}

.pl11 {
  padding-top: 280px;
  right: 10%;
  width: 68px;
  height: 68px;
}

.lph {
  position: absolute;
  top: -4%
}

.prevCard {
  left: 0;
  opacity: 0;
  z-index: -1;
}

.activeCard {
  left: 50%;
  transform: translateX(-50%);
}

.nextCard {
  left: 100%;
  transform: translateX(-100%);
  opacity: 0;
  z-index: -1;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.mt-4 {
  cursor: pointer;
  margin-top: 4px;
}

.leftBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -0.4rem;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 5px 25px #00000033;
  z-index: 5;
}

.rightBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.4rem;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 5px 25px #00000033;
  z-index: 5;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 396px;
  height: 360px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

article {
  position: absolute;
  width: 280px;
  height: 80%;

  transition: all 0.8s ease-in-out;
}

.paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  bottom: 0.4%;
  left: 50%;
  transform: translateX(-50%);

  width: 35%;
  max-width: 30rem;
  height: 2rem;
}

.hr {
  position: absolute;

  width: 0%;
  height: 2px;

  background-color: #eee;
}

.dot {
  height: 14px;
  width: 14px;

  border-radius: 50%;

  background-color: #bebebe;
  z-index: 1;

  transition: all 1s;
}

.dot:hover {
  cursor: pointer;
}

.dot.active {
  box-shadow: 0 0 0 4px #bebebe;
  background-color: white;
}

.mb-12 {
  margin-top: -12px;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 95px;
  right: 20px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 14;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media only screen and (max-width: 1300px) {

  .carouspan {
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 30px */
    align-self: baseline;
  }
  .carous3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 99%;
    margin: auto;
    margin-left: 4em;
    margin-top: 2.5em;
    margin-bottom: -1.5em;
    gap: 2em;
  }


}

/* for mobile */
@media only screen and (max-width: 1100px) {

  .nav-logo {
    float: left;
    display: block;
    margin-left: 40px;
    z-index: 8;
    margin-top: -1.7em;
  }

  .hero-subtitle2 {
    width: 50%;
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    margin-top: -2em;
    /*font-family: "Satoshi Variable";*/
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
  }

  .device-grid-container {
    padding-top: 3em;
  }

  .legals {
    gap: 1em;
    flex-wrap: wrap;
  }

  .carou {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    padding-left: 10em;
    gap: 1em
  }

  .car-elt {
    border-radius: 32px;
    background: linear-gradient(180deg, rgba(20, 20, 20, 0.00) 42.86%, #141414 78.4%);
    width: 240px;
    height: 360px;
    flex-shrink: 0;
    padding-bottom: 3.5em;
    overflow: hidden;
    text-align: center;
  }

  .carouspan1 {
    color: var(--White, #FFF);
    /*font-family: "Satoshi Variable";*/
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 30px */
    align-self: baseline;
  }

  .carous2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: -1.5em;
    gap: 0.1em;
  }

  .whatsapp-icon {
    margin-top: 10px;
  }

  .botsect1 {
    width: 100%;
    padding-top: 2em;
    position: relative;
    /*justify-content: center;*/
    /* overflow: hidden; */
    /*z-index: 4;*/
  }

  .phone_in_hand {
    position: relative;
    /*justify-self: center;*/
    /*align-self: center;*/
    left: 40%;
    margin-bottom: -1em;
    /*flex-shrink: 0;*/
  }

  .discover {
    position: absolute;
    top: 50%;
    left: 30%;
  }

  .disk {
    position: absolute;
    /*display: none;*/
    top: 50%;
    left: 0;
  }

  .playlist {
    position: absolute;
    right: 10%;
    display: none;
    top: 40%;
  }

  .song1 {
    position: absolute;
    top: 72%;
    display: none;
    right: 5%;
  }

  .song2 {
    position: absolute;
    top: 25%;
    display: none;
    left: 15%;
  }

  .listening1 {
    display: none;
    position: absolute;
    right: 5%;
    top: 2%;
  }

  .listening2 {
    display: none;
    position: absolute;
    top: 2%;
    left: 15%;
  }

  .event {
    display: none;
    position: absolute;
    top: 2%;
    left: 35%;
  }

  .icon {
    position: absolute;
    right: 10px;
    bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1000 !important;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 6px;
    right: 10px;
    font-size: 22px;
  }

  .freedown {
    padding: 10px 18px;
    justify-content: center;
    margin-right: 1%;
    align-items: center;
    border-radius: 24px;
    border: 0.609px solid rgba(255, 255, 255, 0.16);
    background: #FF5620;
    color: white;
    position: relative;
    top: -4.5em;
    z-index: 1000;
  }

  .hero-title {
    padding-top: 1em;
    width: 85%;
    margin: auto;
    font-size: 30px;
    letter-spacing: -0.41px;
    line-height: 48.76px;
  }

  .hero-subtitle {
    width: 70%;
    margin: auto;
    margin-top: -2em;
    font-size: 18px;

  }

  .premium-cta {
    width: 100%;
    margin-left: 20px;
  }

  .fpd {
    width: 80px;
    text-align: left;
    text-overflow: unset;
  }

  .fph h3 {

    font-size: 40px;
  }

  .sc {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .scdc h5 {
    width: 100%;
    text-align: center;
  }

  .gifco {
    position: relative;
  }
}

.mr-20 {
  margin-right: 20px;
}
.carous3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 99%;
  margin: auto;
  margin-left: 4em;
  margin-top: 2.5em;
  margin-bottom: -1.5em;
  gap: 2em;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}


@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-25px);
  }

  100% {

    transform: translatey(0px);
  }
}

@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.rotating {
  -webkit-animation: rotating 20s linear infinite;
  -moz-animation: rotating 20s linear infinite;
  -ms-animation: rotating 20s linear infinite;
  -o-animation: rotating 20s linear infinite;
  animation: rotating 20s linear infinite;
}

.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 18);
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 100px;
  margin: 0 4px 0 4px;
}

.fadel {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 2;
  top: 0;
  background: linear-gradient(to left, red 100%, transparent 0%);
}

@media only screen and (max-width: 1000px) {
  .carous2 {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: -1.5em;
    gap: 0.1em;
  }
}

@media only screen and (max-width: 500px) {

  .carous2 {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: -1.5em;
    margin-bottom: -1.5em;
    gap: 0.1em;
  }


  .carous-elt {
    width: auto;
    /* margin-right: 0;
    padding-bottom: 0; */
    gap: 0.5em;
  }

  .subimg {
    flex-shrink: 0;
    display: flex;
    gap: 0.1em;
    justify-content: center;
    align-content: center;
    padding: 1em;
    margin: 0;
  }

  .subimgp {
    width: 24px;
    height: 24px;
  }

  .subtil {
    color: var(--White, #FFF);
    text-align: center;
    /*font-family: "Satoshi Variable";*/
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 27.746px */
    align-self: stretch;
  }

  .icon {
    position: fixed;
    /* ou absolute, selon le besoin */
    left: 50%;
    /* Centrer horizontalement */
    transform: translateX(-50%);
    /* Ajustement fin pour centrer exactement */
    bottom: 10px;
    /* ou ajustez selon la position verticale dÃ©sirÃ©e */
    right: auto;
    /* Annuler tout autre style 'right' qui pourrait interfÃ©rer */
  }

  .subsubtil {
    width: 140px;
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    /*font-family: "Satoshi Variable";*/
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
    align-self: stretch;
  }

  .premium-cta {
    width: 100%;
  }
  .fond6 {
  position: absolute;
  background: url("./assets/new_images/last.svg");
  width: 100% !important;
  height: 150%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  bottom: 0;
  left: 0;
  /*display: block;*/
}

  .btn-freedown {
    display: flex;
    padding: 20px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 54px;
    background: #FF5620;
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
  }

  .hero-title {
    padding-top: 3em;
    width: 60%;
    margin: auto;
    font-size: 24px;
    letter-spacing: -0.41px;
    line-height: 30px;

  }

  .hero-subtitle {
    width: 85%;
    margin: auto;
    margin-top: -1em;
    font-size: 14px;
    line-height: 21px;
  }


  /*.nav-logo{*/
  /*  margin-top: 1em;*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  left: 1em;*/
  /*}*/

  /* .device-grid-container{
    overflow: hidden;
    height: fit-content;
  } */

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }

  .imgbtn {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 1em;
  }

  .btn-disc {
    display: flex;
    font-size: 14px;
    position: relative;
    width: 162px;
    height: 40px;
  }

  .back5{
    display: none;
  }

  .btn-disc3 {
    display: flex;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    width: 195px;
    height: 40px;
    bottom: -28px;
  }

  .pointImg{
    padding-bottom: 1em !important;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
  }
  .point{
    width: 100%;
    height: 219px;
  }
  .background-container {
    margin: 0.6em;
    border-radius: 30px;
    margin-top: 6em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('assets/new_images/carousmall.png'), url('assets/new_images/transparent.png');
    background-blend-mode: multiply; /* Exemple de mode de fusion, ajustez selon le besoin */
    background-size: cover; /* Assure que les images couvrent entiÃ¨rement l'Ã©lÃ©ment */
    background-position: center; /* Centre les images dans l'Ã©lÃ©ment */
    background-repeat: no-repeat; /* EmpÃªche la rÃ©pÃ©tition des images */
    height: 256px; /* DÃ©finissez la hauteur nÃ©cessaire pour votre design */
    width: 380px; /* La largeur peut Ãªtre ajustÃ©e selon le design */
  }

  .btn-disc2 {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    position: relative;
    width: 110px;
    height: 40px;
  }

  .btntext {
    position: absolute;
    left: 1em;
    font-size: 14px;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    text-decoration: none;
  }

  .btntext2 {
    position: absolute;
    left: 1em;
    font-size: 14px;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
  }

  .btntext3 {
    position: absolute;
    font-size: 14px;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
  }

  .btn-freedown {
    font-size: 14px;
    width: 162px;
    height: 40px;
  }

  carous {
    display: flex;
    margin-bottom: 1px;
    gap: 1em;
    background: transparent;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 530px;
    right: 0;
    margin-left: 0;
    position: relative;
  }

  .carous3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 99%;
    margin: auto;
    margin-top: 2.5em;
    margin-bottom: -1.5em;
    gap: 2em;
  }

  .carousimgprime {
    padding-bottom: 2em;
    height: 300px;
    width: 180px;
  }

  .carousimg {
    width: 60px; /* Taille fixe pour les petites images */
    height: 60px; /* Ajout pour maintenir le ratio */
    object-fit: cover; /* Pour s'assurer que l'image couvre bien le cercle */
    border-radius: 50%;
  }

  .carousspan {
    font-size: 12px;
    width: 100%; 
  }

  .carouimg2 {
    width: 100%;
    height: 100%;
    background: #25370E;
    display: block;
    object-fit: fill;
    mask-image: linear-gradient(to top, transparent, black);
  }

  .carouimg22 {
    width: 100%;
    height: 100%;
    background: #3B3DD9;
    display: block;
    object-fit: fill;
    mask-image: linear-gradient(to top, transparent, black);
  }

  .imgbtn {
    width: 18px;
    height: 18px;
  }

  .hero-subtitle2 {
    width: 100%;
    margin-top: -3.5em;
    font-size: 15px;
  }

  .hero-subtitle3 {
    margin-top: -2em;
    font-size: 14px;
    width: 95%;
  }

  .hero-subtitle6 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    width: 90%;
  }

  .why-head {
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .why-head1{
    width: 100%;
    margin-top: -2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;
    margin: auto;
  }
  

  .why-title {
    font-size: 24px;
    width: 95%;
    margin-bottom: 3em;
    margin-top: 2em;
  }

  .why-title1 {
    color: var(--White, #FFF);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    width: 95%;
  }
  .why-title2 {
    color: var(--White, #FF5620);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    width: 95%;
  }

  .premiumpart{
    display: inline;
    width: 100%;
  }
  .left{
    width: 100%;
  }
  .why-title3 {
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    width: 95%;
  }

  .why-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    padding-bottom: 24px;
  }

  .carou {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 11em;
  }

  .carouspan1 {
    width: 10%;
    text-align: right;
    font-size: 12px;
  }

  .car-elt {
    width: 171px;
    height: 269px;
    border-radius: 20px;
  }

  .carous-elt1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    text-align: center;
    gap: 0.5em;
  }

  .placeholder {
    width: 100%;
    height: 90%;
    position: relative;
  }

  .placeholder1 {
    width: 100%;
    height: 90%;
    position: relative;
  }

  .ftr-content3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 0;
    margin-left: -1em;
  }

  .legals {
    padding: 1em;
    width: 80%;
    margin: auto;
  }

  .freedown {
    height: 35px;
    right: 3em;
    top: -6.6em;
    font-style: normal;
    font-weight: 300;
    line-height: 125%;
    justify-content: center;
    margin-right: 2em;
  }

  .botsect1 {
    width: 100%;
    padding-top: 2em;
    position: relative;
    /*justify-content: center;*/
    /* overflow: hidden; */
    /*z-index: 4;*/
  }

  .phone_in_hand {
    position: relative;
    /*justify-self: center;*/
    /*align-self: center;*/
    left: 26%;
    /*flex-shrink: 0;*/
    width: 343.32px;
    height: 487.99px;
  }

  .discover {
    display: block;
    position: absolute;
    top: 75%;
    /*display: none;*/
    bottom: 3em;
    left: 1em;
    width: 100px;
    height: 100px;
  }

  .disk {
    position: absolute;
    top: 25%;
    /*display: none;*/
    bottom: 3em;
    left: 0.3em;
    width: 110px;
    height: 110px;
  }

  .playlist {
    position: absolute;
    right: 5%;
    display: block;
    top: 9%;
    width: 80px;
    height: 80px;
  }

  .event {
    display: block;
    position: absolute;
    top: 26%;
    left: 310px;
    height: 80px;
    width: 80px;
  }

  .song1 {
    position: absolute;
    bottom: 0;
    right: 5em;
  }

  .song2 {
    position: absolute;
    top: 25%;
    display: none;
    left: 15%;
  }

  .listening1 {
    display: none;
    position: absolute;
    right: 5%;
    top: 2%;
    width: 70px;
    height: 70px;
  }

  .listening2 {
    display: none;
    position: absolute;
    top: 2%;
    left: 10%;
    width: 70px;
    height: 70px;
  }

  .ftr-logo {
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 1em;
    left: 3em;
  }

  .ftr-logo-img {
    width: 100px;
  }

  .links {
    width: 30px;
    height: 30px;
  }

  .links5 {
    width: 30px;
    height: 30px;
  }

  .links3 {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .links1 {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .links4 {
    height: 30px;
    cursor: pointer;
  }
  .links2 {
    width: 17px;
    height: 30px;
    cursor: pointer;
  }


  .follow {
    gap: 0.1em;
    margin-left: -0.5em;
  }

  /*---------------------*/

  .copywright {
    width: 100%;
    font-weight: 400;
    font-size: 22px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #141414;
    margin-bottom: -2em;
  }

  .langg {
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    background: #3aa374;
    border-radius: 1000px;
  }

  .legals {
    width: 100%;
    display: flex;
    gap: 0.5em;
    color: rgba(255, 255, 255, 0.80);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
  }

  .cpwright {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  .legals a {
    text-decoration: underline !important;
  }

  .languages {
    display: flex;
    align-items: center;
    gap: 0.2em;
    padding: 0.3em;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.12);
  }

  .languages span {
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
  }


  .fond3 {
    background: url("./assets/new_images/blurright.svg");
    position: absolute;
    background-size: contain;
    width: 1000px;
    height: 480px;
    top: 11em;
    background-position: left;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }
  
  .fond2{
    display: none;
  }

  .fond5 {
    position: absolute;
    background: url("./assets/new_images/blurleft.svg");
    width: 100vw !important;
    height: 1090px;
    bottom: 0;
    display: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }

  .fond4 {
    position: absolute;
    background: url("./assets/new_images/noise.svg");
    top: 0;
    width: 100vw !important;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    background-position: center;
    height: 1090px;
    flex-shrink: 0;
  }


  .lsdro {
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-right: 2px;
    color: white;
    outline: none;
    padding: 0px;
  }

  .lsdrp {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
    margin-left: 20px;
  }

  .carouimg3 {
    width: 400px;
    overflow: hidden;
    background: red;
    display: block;
    object-fit: contain;
    mask-image: linear-gradient(to top, transparent, black);
  }


  .footer1 {
    position: relative;
    width: 90%;
    margin: auto;
    /*padding: 28px 250px 70px 150px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.1em;
    color: #fff;
    padding-bottom: 1em;
  }

  .footer {
    position: relative;
    width: 100%;
    background: black;
    color: #fff;
  }

  .cont {
    font-weight: 500;
  }

  .ftr-content2 h3 {
    font-weight: 700;
  }

  .ftr-content2 ul li {
    font-weight: 100;
  }


  .ftr-content h3 {
    font-weight: 700;
  }

  .ftr-content ul li {
    font-weight: 100;
  }

  .carouimg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    /* display: block;
    object-fit: cover; */
    mask-image: linear-gradient(to top, transparent, black);
  }
  .right{
    display: none;
  }

}


@media only screen and (max-width: 500px) {

  .nav-logo {
    position: absolute;
    top: 1.8em;
    left: 1em;
  }

}

@media only screen and (max-width: 390px) {

  .ftr-logo {
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 1.5em;
    left: 0;
  }


  .nav-logo {
    margin-top: 1em;
    position: absolute;
    top: 0;
    left: 1em;
  }

  .freedown {
    display: none;
  }

  .fond {
    height: 100%;
  }

  .fond2 {
   display: none;
  }

  .carou {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 15em;
  }

  .icon {
    overflow: visible;
    position: absolute;
    /* left: 50%; */
    transform: translateX(-50%);
    bottom: -3px;
  }

  .car-elt {
    width: 100%;
    height: 568px;
    border-radius: 20px;
  }

  .carouspan1 {
    display: block;
    width: 312px;
    text-align: center;
    font-size: 20px;
    padding: 5px;
    padding-top: 10px !important;
  }

  .carousimgprime {
    display: none;
  }

  .carous {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1px;
    gap: 1em;
    background: transparent;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 530px;
    right: 0;
    margin-left: 0;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .right{
    display: none;
  }

}