.eventImage > img {
  width: 400px;
}

.eventContainer{
    height: auto;
}

@media screen and (max-width: 1300px) {
  .eventContainer {
    /*width: calc(100% - 80px);*/
    margin: auto;
    padding-inline: 0;
  }

  .eventSubContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-inline: 40px;
  }

  .eventTop {
    padding: 0;
  }

  .eventTop > h3 {
    font-size: 40px;
  }
}

@media screen and (max-width: 1100px) {
  .eventImage > img {
    width: 300px;
  }
}

@media screen and (max-width: 1000px) {
  .eventContainer {
    /*width: calc(100% - 40px);*/
    margin: auto;
    padding-inline: 0;
  }
}

@media screen and (max-width: 950px) {
  .eventBottom {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  .eventSteps,
  .eventSteps div {
    width: 100%;
  }

  .eventSteps > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .eventSteps > div > span {
    margin: 0;
  }

  .eventSteps > div > div > p {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .eventContainer {
    width: 100%;
    margin: auto;
    padding-inline: 0;
  }

  .eventSubContainer {
    padding-inline: 20px;
  }

  .eventTop {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .eventTop > h3 {
    /* text-align: left; */
    font-size: 40px;
  }
}