.deviceContainer {
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1300px) {
  .deviceContainer {
    overflow: hidden;
  }

  .deviceBoxContainer {
    position: absolute;
    top: 110px;
    right: 100px;
    width: 400px;
    height: 400px;
  }

  .deviceImageContainer {
    position: absolute;
    top: 110px;
    right: 88px;
    width: 350px;
    height: 350px;
  }

  .deviceMac {
    width: 700px;
    height: auto;
    margin: 80px 0 0 0;
  }

  .deviceMac > img {
    margin-left: 55%;
    margin-top: 200px
  }

  .devicePhone {
    width: 150px;
    height: auto;
    margin: 20px 0 0 0;
  }

  .devicePhone > img {
    margin-top: 30%;
  }

  .deviceInfo {
    margin-top: 100px;
    width: 500px;
    margin-left: 60px;
  }

  .deviceInfo h2 {
    line-height: 60px;
  }
}

@media screen and (max-width: 1125px) {
  .deviceBoxContainer, 
  .deviceImageContainer, 
  .deviceMac, 
  .devicePhone {
    display: none;
  }

  .deviceContainer > div:first-child,
  .deviceInfo {
    width: 100%;
    display: block;
  }

  .deviceInfo {
    margin-left: 0;
    padding: 20px
  }

  .deviceInfo h2,
  .deviceInfo h6 {
    margin: 0;
    width: 100%;
  }

  .deviceInfo h6 {
    margin-block: 30px;
  }
}

@media screen and (max-width: 800px) {
  .deviceInfo h2 {
    font-size: 40px
  }

  .deviceInfo h6 {
    font-size: 22px
  }
}

@media screen and (max-width: 576px) {
  .deviceInfo h2 {
    line-height: 50px;
  }
}